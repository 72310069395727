import * as React from 'react';
import { styled } from '../../Layout/ThemeProvider/ThemeProvider';
import { SupportedBanks } from '../UploadFileToConvert/UploadFileToConvert';

const mBiznesLogo = require('../../../assets/mBank_logo.jpeg');
const mBankLogo = require('../../../assets/mbank-logo-ind.gif');
const mBankCorpLogo = require('../../../assets/mbank-logo-corpo.gif');
const pkoBpLogo = require('../../../assets/pko_bp_logo.png');
const ideabankLogo = require('../../../assets/idea-bank-logo.jpg');
const sgbLogo = require('../../../assets/sgb_logo.png');
const aliorLogo = require('../../../assets/alior-logo.png');
const fgcLogo = require('../../../assets/fgc_logo.jpg');
const iPkoLogo = require('../../../assets/ipko-logo.png');

export const PickerContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const Separator = styled.div`
  width: 80%;
  height: .2rem;
  border: .1rem solid ${({ theme }) => theme.colors.secondary9};
`;

const PickerOption = styled.div`
  cursor: pointer;
  width: 25rem;
  height: 14rem;
  margin: 2rem;
  font-size: 1.4rem;
  text-align: center;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 2rem;
  border: .2rem solid ${({ theme }) => theme.colors.secondary7};
  font-weight: bold;

  .imgContainer {
    width: 100%;
    max-height: 70%;
  }

  img {
    max-height: 100%;
    width: auto;
    margin-bottom: 1rem;
  }

  &.selected, &:hover {
    border: .2rem solid ${({ theme }) => theme.colors.secondary3};
  }

  &.selected {
    background: ${({ theme }) => theme.colors.secondary3};
    color: ${({ theme }) => theme.colors.primary7};
  }
`;

const PrimaryOption = styled.div`
  @media (${({ theme: { deviceBreakpoints } }) => deviceBreakpoints.mobile}) {
    width: 35rem;
  }
  
  width: 25rem;
  height: 14rem;
  margin: 2rem;
  font-size: 2.2rem;
  text-align: center;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 2rem;
  border: .2rem solid ${({ theme }) => theme.colors.secondary7};
  font-weight: bold;
`

// tslint:disable-next-line
const arrow = '<svg fill="black" height="5px" viewBox="0 0 10 5" width="10px" xmlns="http://www.w3.org/2000/svg"><path d="M 0 0 L 5 5 L 10 0 Z"/><path d="M0 0h24v24H0z" fill="none"/></svg>';

const DropDown = styled.select`
  z-index: 1;
  font-size: 1.2rem;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url('data:image/svg+xml;utf8, ${arrow}');
  background-repeat: no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: 50%;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  padding: .3rem;
  padding-right: 2rem;
  width: 11rem;
  margin: 0 0 0 1rem;
`;
type ChildrenParams = {
  bankName?: string;
  changeBankName(bankName: string);
}

type Props = {
  defaultValue: string;
  children: (params: ChildrenParams) => React.ReactNode;
};

const StatefulPickerOption: React.FC<Props> = (props) => {
  const [bankName, changeBankName] = React.useState<string | undefined>(props.defaultValue);
  return (
    <>
      {props.children({
        bankName,
        changeBankName,
      })}
    </>
  );
};

export const BankPickerContainer: React.FC<{ setBankName: (bankName?: SupportedBanks) => void }> = ({ setBankName }) => (
  <PickerContainer>
    <PrimaryOption
      onClick={() => {
        setBankName();
      }}
    >
      {'wykryj automatycznie'}
    </PrimaryOption>
    <Separator/>
    <PickerOption
      onClick={() => {
        setBankName('iPko');
      }}
    >
      <div className="imgContainer"><img src={iPkoLogo}/></div>
      {'iPKO - wyciąg csv'}
    </PickerOption>
    <PickerOption
      onClick={() => {
        setBankName('mBiznes');
      }}
    >
      <div className="imgContainer"><img src={mBiznesLogo}/></div>
      {'mBiznes - wyciąg pdf'}
    </PickerOption>
    <PickerOption
      onClick={() => {
        setBankName('mBiznesEzo');
      }}
    >
      <div className="imgContainer"><img src={mBiznesLogo}/></div>
      {'mBiznes - e. zestawienie opreacji pdf'}
    </PickerOption>
    <PickerOption
      onClick={() => {
        setBankName('mBankCorpHtml');
      }}
    >
      <div className="imgContainer"><img src={mBankCorpLogo}/></div>
      {'mBank korpo - wyciąg html'}
    </PickerOption>
    <PickerOption
      onClick={() => {
        setBankName('mBankCorpCsv');
      }}
    >
      <div className="imgContainer"><img src={mBankCorpLogo}/></div>
      {'mBank korpo - wyciąg csv'}
    </PickerOption>
    <PickerOption
      onClick={() => {
        setBankName('mBank');
      }}
    >
      <div className="imgContainer"><img src={mBankLogo}/></div>
      {'mBank - wyciąg pdf'}
    </PickerOption>
    <PickerOption
      onClick={() => {
        setBankName('mBankCsv');
      }}
    >
      <div className="imgContainer"><img src={mBankLogo}/></div>
      {'mBank - wyciąg csv'}
    </PickerOption>
    <PickerOption
      onClick={() => {
        setBankName('mBankLo');
      }}
    >
      <div className="imgContainer"><img src={mBankLogo}/></div>
      {'mBank - lista operacji pdf'}
    </PickerOption>
    <PickerOption
      onClick={() => {
        setBankName('pkoBp');
      }}
    >
      <div className="imgContainer"><img src={pkoBpLogo}/></div>
      {'wyciąg pdf'}
    </PickerOption>
    <PickerOption
      onClick={() => {
        setBankName('ideabank');
      }}
    >
      <div
        className="imgContainer"
        style={{ backgroundColor: '#3B99F7' }}
      >
        <img src={ideabankLogo}/>
      </div>
      {'wyciąg pdf'}
    </PickerOption>
    <PickerOption
      onClick={() => {
        setBankName('spoldzielczy');
      }}
    >
      <div className="imgContainer"><img src={sgbLogo}/></div>
      {'wyciąg pdf'}
    </PickerOption>
    <PickerOption
      onClick={() => {
        setBankName('spoldzielczyV2');
      }}
    >
      <div className="imgContainer"><img src={sgbLogo}/></div>
      {'wyciąg pdf (v2)'}
    </PickerOption>
    <StatefulPickerOption
      defaultValue="alior-optima"
    >
      {({ bankName, changeBankName }) => (
        <PickerOption
          onClick={() => {
            bankName && setBankName(bankName);
          }}
        >
          <div className="imgContainer">
            <img
              src={aliorLogo}
              style={{ transform: 'scale(1.4) translateY(-15%)' }}
            />
          </div>
          <div style={{ marginTop: '.3rem' }}>
            wyciąg pdf dla
            <DropDown
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => {
                e.target && e.target.value && changeBankName(e.target.value);
              }}
            >
              <option value="alior-optima">Optima</option>
              <option value="alior-quick-books">QuickBooks</option>
            </DropDown>
          </div>
        </PickerOption>
      )}
    </StatefulPickerOption>
    <PickerOption
      onClick={() => {
        setBankName('alior-csv-quick-books');
      }}
    >
      <div className="imgContainer">
        <img
          src={aliorLogo}
          style={{ transform: 'scale(1.4) translateY(-15%)' }}
        />
      </div>
      {'wyciąg csv (dla QuickBooks)'}
    </PickerOption>
    <PickerOption
      onClick={() => {
        setBankName('fgc-csv-quick-books');
      }}
    >
      <div className="imgContainer">
        <img
          src={fgcLogo}
          style={{ transform: 'scale(0.8)' }}
        />
      </div>
      {'wyciąg csv (dla QuickBooks)'}
    </PickerOption>
  </PickerContainer>
);
